import { useUserStore } from '@/stores/user';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();
  await userStore.fetchUser();

  if (!userStore.user.is_whitelisted) {
    return navigateTo('/free');
  }
});
